import { Box, Button } from "@mui/material";
import React from "react";
import { specificGame } from "../specificGames";

type PlayNowButtonProps = {
  game: specificGame;
};

const PlayNowButton: React.FC<PlayNowButtonProps> = ({ game }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          top: "-2.5rem",
          position: "relative",
          textAlign: "center",
          flexDirection: "column",
          zIndex: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "16rem",
            height: "5rem",
            bgcolor: "secondary.main",
            fontSize: "2rem",
            boxShadow: 5,
            borderRadius: 5,
            border: "5px solid #514066",
            "&:hover": {
              bgcolor: "secondary.dark",
            },
          }}
          href={`${game.url}/purchase`}
          target="_blank"
        >
          PLAY NOW
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default PlayNowButton;
