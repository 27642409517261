import { Box, Typography } from "@mui/material";
import React from "react";

const Description: React.FC = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          my: 5,
          px: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            maxWidth: { md: "70%" },
          }}
          gutterBottom
        >
          We are an online software studio and creative outlet based in
          Bangladesh, exploring the interwebs through apps and games! We had
          initiated in 2015, making only amateur 2D jRPGs. We have since
          expanded our reach of internet products & services and have been
          slowly working on other web applications and app integrations. We are
          currently a small team of single digits, but we are gradually
          increasing.
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default Description;
