import { Button, Grid, Typography, useTheme } from "@mui/material";
import GameSvg from "../../assets/images/gaming.svg";
import backgroundImage from "../../assets/images/games_background.jpg";

import { Link } from "react-router-dom";

const GamesSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.7)", // Add a dark overlay tint
        height: "70vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        overflow: "hidden",
        padding: theme.spacing(2),

        "@media (max-width: 600px)": {
          height: "80vh",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          animation: "fadeInLeft 1s",
          textAlign: { xs: "center" },
          px: { xs: 0, sm: "2rem" },
        }}
      >
        <img src={GameSvg} alt="Games" />
      </Grid>

      <Grid item xs={12} sm={6} sx={{ animation: "fadeInLeft 1s" }}>
        <Typography variant="h1">Games</Typography>
        <Typography variant="body1" gutterBottom>
          Check out our range of games, especially the Calgwinverse series!
        </Typography>
        <Link to="/games">
          <Button variant="contained" color="secondary" size="large">
            See Games
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default GamesSection;
