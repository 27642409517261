import React from "react";
import { Box } from "@mui/material";

import FooterSocials from "./components/Socials";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Copyright from "./components/Copyright";

import "../layout.css";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        padding: "1rem",
        textAlign: "center",
        borderTop: "1px solid #e0e0e0",
      }}
      className="background"
    >
      <FooterSocials />
      <ScrollToTopButton />
      <Copyright />
    </Box>
  );
};

export default Footer;
