import React, { useState } from "react";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
} from "@mui/material";

interface GameScreenshotsProps {
  screenshots: string[];
}

const GameScreenshots: React.FC<GameScreenshotsProps> = ({ screenshots }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (image: string) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: "inherit",
      }}
    >
      <ImageList cols={1}>
        {screenshots.map((screenshot, index) => (
          <ImageListItem key={index} onClick={() => openModal(screenshot)}>
            <img
              src={screenshot}
              alt={`Screenshot ${index + 1}`}
              style={{
                width: "100%",
                height: "auto",
                display: "block",
                margin: "auto",
                cursor: "pointer",
              }}
            />
            <ImageListItemBar title={`Screenshot ${index + 1}`} />
          </ImageListItem>
        ))}
      </ImageList>

      <Modal open={selectedImage !== null} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%",
          }}
        >
          <img
            src={selectedImage || ""}
            alt="Enlarged Screenshot"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default GameScreenshots;
