import React from "react";
import { specificGame } from "../specificGames";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type GameDetailsProps = {
  game: specificGame;
};

const GameDetails: React.FC<GameDetailsProps> = ({ game }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        maxWidth: "600px",
        backgroundColor: game.backgroundColor,
        mx: { xs: 1 },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "primary.contrastText" }} />} // Use the imported ExpandMoreIcon component
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>Game Info</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table
            sx={{ minWidth: { xs: "100%", sm: "400px" } }}
            aria-label="simple table"
            size="small"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell align="right">{game.name}</TableCell>
              </TableRow>
              {Object.keys(game.details).map((key) => {
                const detail = game.details[key as keyof typeof game.details];
                return (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key
                        .replace(/([a-z])([A-Z])/g, "$1 $2")
                        .charAt(0)
                        .toUpperCase() +
                        key.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)}
                    </TableCell>
                    <TableCell align="right">
                      {Array.isArray(detail) ? detail.join(", ") : detail}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default GameDetails;
