import React, { useEffect } from "react";
import GridLayout from "../components/Games/GridLayout";
import { Typography } from "@mui/material";

const Games: React.FC = () => {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{ mt: 2, mb: 5, textAlign: "center" }}
        gutterBottom
      >
        Games
      </Typography>
      <GridLayout />
    </React.Fragment>
  );
};

export default Games;
