import { faComputer, faGamepad, faGlobe } from "@fortawesome/free-solid-svg-icons";

export type roadmapDetails = {
    id: number;
    title: string;
    software?: string;
    description: string;
    date: string;
    status?: { text: string, color: string };
    icon: any
}

export const roadmapDetailsList : roadmapDetails[] = [
    {
        id: 1,
        title: "Destiny Online",
        software: "Engine: RPG Maker VX Ace",
        description: "Not really an online game, but it served as the building block for our pilot title.",
        date: "2015-2016",
        icon: faGamepad
    },
    {
        id: 2,
        title: "Land of Calgwin: Beta 0.2b",
        software: "Engine: RPG Maker VX Ace",
        description: "Progressing through the journey of building a franchise series piece by piece.",
        date: "2016-2018",
        icon: faGamepad
    },
    {
        id: 3,
        title: "Land of Calgwin: Beta 1.1.x",
        software: "Engine: RPG Maker MV",
        description: "Transitioning to a new engine to improve the overall quality of the game.",
        date: "Sep 2018",
        icon: faGamepad
    },
    {
        id: 4,
        title: "Deserter",
        software: "Engine: RPG Maker MV",
        description: "Our first collaboration project, alongside VIGA Manufacture and Fantales Interactive (This was an entry for Indie Game Maker Contest 2018).",
        date: "Oct 2018",
        icon: faGamepad
    },
    {
        id: 5,
        title: "Land of Calgwin: Graphics Update",
        software: "Engine: RPG Maker MV",
        description: "A major update to the graphics of the game, improving the overall look and feel of the game.",
        date: "2018-2019",
        icon: faGamepad
    },
    {
        id: 6,
        title: "Land of Calgwin: Bug Smashing Update",
        software: "Engine: RPG Maker MV",
        description: "A major update to the game, fixing all the bugs and issues that were present in the game.",
        date: "2019-2020",
        icon: faGamepad
    },
    {
        id: 7,
        title: "Land of Calgwin: Beta 1.2.x",
        software: "Engine: RPG Maker MV",
        description: "A major update to the game, adding new features and content to the game.",
        date: "2020-2021",
        icon: faGamepad
    },
    {
        id: 8,
        title: "Land of Calgwin: Origin-A",
        software: "Engine: RPG Maker MV",
        description: "An old spin-off of the main series, focusing on the origin of a character from the main series. This was an entry for Brackeys Game Jam 2020.2",
        date: "Aug 2020",
        icon: faGamepad
    },
    {
        id: 9,
        title: "Land of Calgwin: Prologue",
        software: "Engine: RPG Maker MV",
        description: "The final release of the first game in the series, serving as a prologue to the main series.",
        date: "Sep 2020",
        icon: faGamepad
    },
    {
        id: 10,
        title: "Christmas in Peril",
        software: "Engine: RPG Maker MV",
        description: "A short game made for Christmas 2020, serving as an entry for the Big Festive Game Jam 2020",
        date: "Dec 2020",
        icon: faGamepad
    },
    {
        id: 11,
        title: "Pursuit for Gaia",
        software: "Engine: RPG Maker MZ",
        description: "ur biggest collaboration title with Ambassadors of Earth, built on the recent MZ engine!",
        date: "Aug 2021",
        icon: faGamepad
    },
    {
        id: 12,
        title: "Chase of the Ruby",
        software: "Engine: RPG Maker MZ",
        description: "Our latest release with our newly formed team! This builds on the Calgwinverse!",
        date: "Feb 2022",
        icon: faGamepad
    },
    {
        id: 13,
        title: "W-15 Interactive Website (Old)",
        software: "Stack: React, Sass, Halfmoon",
        description: "Our official website, built to showcase our games and projects to the world.",
        date: "2023-2024",
        icon: faGlobe
    },
    {
        id: 14,
        title: "Calgwin Origins: Godi",
        software: "Engine: RPG Maker MZ",
        description: "The second game in the Calgwinverse, it is a remake of Origin-A, with new features and content.",
        date: "Dec 2023",
        icon: faGamepad
    },
    {
        id: 15,
        title: "Calgwin EduLabs",
        description: "A platform to educate the general public of technology, computer science, and the digital world.",
        date: "Dec 2023 - Now",
        icon: faComputer
    },
    {
        id: 16,
        title: "(NEW) W-15 Interactive Website",
        software: "Stack: React, Material UI, TypeScript",
        description: "The website that you're browsing right now, made using modern technologies.",
        date: "2024 - Now",
        icon: faGamepad
    },
    
]