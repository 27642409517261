import { IconButton } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { scrollToTop } from "../../scrollToTop";

const ScrollToTopButton: React.FC = () => {
  return (
    <IconButton
      sx={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        bgcolor: "secondary.main",
        color: "primary.contrastText",
      }}
      onClick={() => scrollToTop(true)}
    >
      <KeyboardArrowUp />
    </IconButton>
  );
};

export default ScrollToTopButton;
