import React from "react";
import HeroSection from "../components/Home/Hero";
import GamesSection from "../components/Home/Games";
import SoftwareSection from "../components/Home/Software";
import CalgwinEdulabsSection from "../components/Home/CalgwinEdulabs";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <GamesSection />
      <SoftwareSection />
      <CalgwinEdulabsSection />
    </React.Fragment>
  );
};

export default Home;
