import React, { useEffect } from "react";

import { specificGamesList, specificGame } from "./specificGames";
import { useParams } from "react-router-dom";
import GameHero from "./components/GameHero";
import GameDescription from "./components/GameDescription";
import PlayNowButton from "./components/PlayNowButton";

const SpecificGame: React.FC = () => {
  const { gameId } = useParams();
  const game: specificGame = specificGamesList.find(
    (game) => game.id === gameId
  )!;

  useEffect(() => {
    document.title = game.name + " | W-15 Interactive";
  }, [game]);

  return (
    <React.Fragment>
      <GameHero game={game} />
      <PlayNowButton game={game} />
      <GameDescription game={game} />
    </React.Fragment>
  );
};

export default SpecificGame;
