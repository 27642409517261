import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import LinkButton from "../../../Custom/LinkButton";

const FooterSocials: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "1rem",
      }}
    >
      <LinkButton
        to="https://www.facebook.com/w15interactive"
        iconButton
        color={theme.palette.text.primary}
        target="_blank"
      >
        <Facebook />
      </LinkButton>

      <LinkButton
        to="https://www.twitter.com/w15interactive"
        iconButton
        color={theme.palette.text.primary}
        target="_blank"
      >
        <Twitter />
      </LinkButton>

      <LinkButton
        to="https://www.instagram.com/w15interactive"
        iconButton
        color={theme.palette.text.primary}
        target="_blank"
      >
        <Instagram />
      </LinkButton>

      <LinkButton
        to="https://www.linkedin.com/company/w15interactive"
        iconButton
        color={theme.palette.text.primary}
        target="_blank"
      >
        <LinkedIn />
      </LinkButton>

      <LinkButton
        to="mailto:contact@w15interactive.com"
        iconButton
        color={theme.palette.text.primary}
      >
        <Email />
      </LinkButton>
    </Box>
  );
};

export default FooterSocials;
