import { Drawer } from "@mui/material";

import { Theme } from "@mui/material/styles"; // Add import statement for Theme

type MobileNavProps = {
  container?: Element | (() => HTMLElement) | null;
  open: boolean;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void; // Change the type of onClose prop
  theme: Theme;
  drawer: JSX.Element;
};

const MobileNav: React.FC<MobileNavProps> = ({
  container,
  open,
  onClose,
  theme,
  drawer,
}: MobileNavProps) => {
  const drawerWidth = 240;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={open}
      onClose={onClose} // Change the prop value to onClose
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default MobileNav;
