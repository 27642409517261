import chaseOfTheRuby from "../../assets/images/games/chaseoftheruby.png";
import pursuitForGaia from "../../assets/images/games/pursuitforgaia.png";
import christmasInPeril from "../../assets/images/games/christmasinperil.png";
import calgwinOriginsGodi from "../../assets/images/games/calgwinoriginsgodi.png";
import deserter from "../../assets/images/games/deserter.png";

export type gameDetails = {
    id: string;
    name: string;
    tagline?: string;
    logo?: string;
    image: string;
    released: string;
    embedId: string;
};

export const games: gameDetails[] = [
    {
        id: "chase-of-the-ruby",
        name: "Chase of the Ruby",
        tagline: "Is it real...?",
        image: chaseOfTheRuby,
        logo: chaseOfTheRuby,
        released: "2022-02-27",
        embedId: "1416880",
    },
    {
        id: "pursuit-for-gaia",
        name: "Pursuit for Gaia",
        tagline: "May the Earth be with You",
        image: pursuitForGaia,
        released: "2021-08-31",
        embedId: "1144992",
    },
    {
        id: "christmas-in-peril",
        name: "Christmas in Peril",
        tagline: "Naughty? Or Nice?",
        image: christmasInPeril,
        released: "2020-12-20",
        embedId: "859394",
    },
    {
        id: "calgwin-origins-godi",
        name: "Calgwin Origins: Godi",
        tagline: "this isn't the same world, is it?",
        image: calgwinOriginsGodi,
        released: "2024-02-09",
        embedId: "722790",
    },
    {
        id: "deserter",

        name: "Deserter",
        image: deserter,
        released: "2018-12-06",
        embedId: "342204",
    },
]